import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "gatsby"
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { translateText, translatePath } from '../config/translation'

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    marginTop: 32,
    '& a': {
      textDecoration: 'none',
    }
  },
}))

const RSVPSubmitted = ({ lang }) => {
  const classes = useStyles()

  return (
    <Container maxWidth='md' className={classes.root}>
      <h2>{ translateText('Thank you for your RSVP!', lang) }</h2>
      <Link to={ translatePath('/', lang) }>
        <Box m="48px" />
        <Button>
          HOME
        </Button>
      </Link>
    </Container>
  )
}

export default RSVPSubmitted