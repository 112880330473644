import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import './invitation-card.css'

const useStyles = makeStyles(() => ({
  root: {
    
  },
  buttonWrapper: {
    transform: 'translate3d(0, 0, 100px)',
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 48,
  },
  replayButton: {
    height: 'auto',
    background: 'none',
    boxShadow: 'none',
  },
}));

const InvitationCardAnimation = ({ rsvpCode }) => {
  const classes = useStyles()
  const [loadingImages, setLoadingImages] = useState(true)
  const [startAnimation, setStartAnimation] = useState(false)

  const handleImageLoaded = (e) => {
    setLoadingImages(false)
    setStartAnimation(true)
  }

  const handleReplay = () => {
    window.scrollTo(0,0);
    var element = document.getElementById("canvas")
    element.classList.remove("start")
    void element.offsetWidth;
    element.classList.add("start")
  }

  return (<Box className={ classes.root }>
    { loadingImages ? <Box component='span' className={classes.spinner}><CircularProgress /></Box> : '' }
    <div id="canvas" className={ startAnimation ? "start" : "pause" }>
      <div id="wrapper">
        <div className="envelope-back-flap-outside"><img alt="invitation" src="/envelopes/flap-outside.png" /></div>
        <div className="envelope-back-flap-inside"><img alt="invitation" src="/envelopes/flap-inside.png" /></div>
        <div className="envelope-back-cut"><img alt="invitation" src="/envelopes/back-cut.png" /></div>
        <div id="card"><img alt="invitation" src="/envelopes/invitation.jpg" onLoad={handleImageLoaded.bind(this)} /></div>
        <div className="envelope-back"><img alt="invitation" src="/envelopes/back.png" /></div>
        <div className="envelope-front"><img alt="invitation" src={`http://kellio.kellyandhelio.com/kellioapi/get-invitation-envelope/${rsvpCode}`}/></div>
        <div id="white-overlay"></div>
      </div>
    </div>
    <Container className={ classes.buttonWrapper }>
      <Button size='small' color='secondary' variant='text' onClick={ handleReplay } className={ classes.replayButton }>
        Replay
      </Button>
    </Container>
  </Box>);
}

export default InvitationCardAnimation