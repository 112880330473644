import React, { Component } from 'react'
import { kellioApiUrl, jsonApiUrl, basicAuth } from '../config/api'
import axios from 'axios'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RSVPGuestForm from '../components/rsvp-guest-form'
import RSVPSubmitted from '../components/rsvp-submitted'
import RSVPError from '../components/rsvp-error'
import InvitationCard from '../components/invitation-card'
import GuestList from '../components/guest-list'

const styles = theme => ({
  invitationWrapper: {
    position: 'relative',
    transform: 'translate3d(0, 0, -10px)',
    marginTop: -32,
  },
  container: {
    position: 'relative',
    zIndex: 100,
    textAlign: 'center',
    padding: 0,
    '&> div': {
      display: 'inline-block',
      width: 'calc(100% - 32px)',
      margin: '0 0 16px 0',
      textAlign: 'left',
      [theme.breakpoints.up(500)]: {
        width: 450,
      },
      [theme.breakpoints.up(740)]: {
        width: 'calc(50% - 32px)',
        '&:nth-child(odd)': {
          margin: '0 8px 16px 16px'
        },
        '&:nth-child(even)': {
          margin: '0 16px 16px 8px'
        }
      },
    },
  },
  pageTitleWrapper: {
    marginBottom: 32,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  pageTitle: {
    fontSize: 48,
    textAlign: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 48,
  },
  submitButtonWrapper: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    padding: '0 42px',
  },
  centerText: {
    textAlign: 'center',
  },
})

class RSVPForm extends Component {
  constructor(props) {
    super(props)
    this.selfRef = null
  }

  state = {
    error: null,
    showFormErrors: false,
    formStatus: 'not_submitted',
    guests: {},
  }

  componentDidMount() {
    this.setState({ formStatus: 'loading_form' })
    const rsvpCode = this.props.pageContext.rsvpCode
    axios.get(`${ kellioApiUrl }/rsvp-guests/${ rsvpCode }`)
    .then(resp => {
      if (resp.status === 200) {
        const guests = resp.data.reduce(function(result, guest) {
          result[guest.id] = {
            id: guest.id,
            name: guest.name || '',
            email: guest.email || '',
            isAttending: guest.is_attending || '',
            notes: guest.notes || '',
          }
          return result;
        }, {})

        this.setState({
          guests: guests,
          formStatus: 'form_loaded',
        })
      }
      else {
        this.setState({
          formStatus: 'form_loading_failed',
        })
      }
    })
    .catch(error => {
      console.log(error)
      this.setState({
        formStatus: 'submission_failed',
      })
    })
  }

  handleGuestDataChange = (guestId, fieldName) => (event) => {
    let newState = Object.assign({}, this.state)
    newState.guests[guestId][fieldName] = event.target.value
    this.setState(newState)
  }

  handleSubmit = () => {
    window.scrollTo(0, this.selfRef.offsetTop)
    if (this.formIsValid()) {
      this.setState({ formStatus: 'loading_form' })

      const guestIds = Object.keys(this.state.guests)
      const patchRequests = guestIds.map((guestId) => {
        const guest = this.state.guests[guestId]
        const url = `${jsonApiUrl}/node/guest/${guestId}`
        const data = {
          "data": {
            "type": "node--guest",
            "id": guestId,
            "attributes": {
              "title": guest.title,
              "field_name": guest.name,
              "field_email": guest.email,
              "field_is_attending": guest.isAttending,
              "field_notes": guest.notes
            }
          }
        }
        const config = {
          "headers": {
            "Authorization": basicAuth,
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
          }
        }
        return axios.patch(url, data, config)
      })

      Promise.all(patchRequests)
      .then((resps) => {
        const success = resps.reduce((result, resp) => {
          if (resp.status !== 200) {
            result = false
          }
          return result
        }, true)
        if (success) {
          this.setState({
            formStatus: 'submitted',
          })
        }
        else {
          this.setState({
            formStatus: 'submission_failed',
          })
        }
        
      })
      .catch(error => {
        console.log(error)
        this.setState({
          formStatus: 'submission_failed',
        })
      })
    }
    else {
      this.setState({showFormErrors: true})
    }
  }

  formIsValid = () => {
    const guestIds = Object.keys(this.state.guests)
    for (let i = 0; i < guestIds.length; i++) {
      const guest = this.state.guests[guestIds[i]]
      if (guest.name.trim() === '' || !['yes', 'no'].includes(guest.isAttending)) {
        return false
      }
    }
    return true
  }

  render() {
    const classes = this.props.classes
    const lang = this.props.pageContext.lang

    let rsvpContent = null
    if (this.state.formStatus === 'form_loading_failed' || this.state.formStatus === 'submission_failed') {
      rsvpContent = <RSVPError lang={lang} />
    }
    else if (this.state.formStatus === 'submitted') {
      rsvpContent = <Container maxWidth="md">
        <RSVPSubmitted lang={lang} />
        <GuestList lang={ lang } />
      </Container>
    }
    else if (this.state.formStatus === 'loading_form') {
      rsvpContent = <Box component='span' className={classes.spinner}><CircularProgress /></Box>
    }
    else {
      const guestForms = Object.keys(this.state.guests).map((key) => {
        const guest = this.state.guests[key]
        return <Box key={key}><RSVPGuestForm data={guest} showFormErrors={this.state.showFormErrors} handleChange={this.handleGuestDataChange} lang={lang} /></Box>
      });
      rsvpContent = <Box>
        <Container maxWidth="md" className={ classes.centerText }>
          { (lang === 'pt') ? <p>Pedimos que confirme sua presença até <strong>1&nbsp;de&nbsp;setembro&nbsp;de&nbsp;2019</strong> para que possamos nos preparar adequadamente.</p> : <p>Please RSVP us no later than <strong>September&nbsp;1,&nbsp;2019</strong> so we can prepare accordingly.</p> }
        </Container>
        <Box m="48px" />
        <Container maxWidth='md' className={ classes.invitationWrapper }>
          <InvitationCard rsvpCode={ this.props.pageContext.rsvpCode } />
        </Container>
        <Box m="32px" />        
        <Container maxWidth='md' className={classes.container}>
          { guestForms }
        </Container>
        <Container className={classes.submitButtonWrapper}>
          <Button className={ classes.submitButton } onClick={ this.handleSubmit }>
            { (lang === 'pt') ? 'ENVIAR' : 'SUBMIT' }
          </Button>
        </Container>
        <Container maxWidth="md">
          <GuestList lang={ lang } />
        </Container>
      </Box>
    }

    const pageName = (lang === 'pt') ? 'PRESENÇA' : 'RSVP'

    return (
      <div ref={ ref => { this.selfRef=ref } }>
        <Layout pageName={ pageName } activeRoute={ this.props.location.pathname } lang={ lang }>
          <SEO title={ pageName } />
          <Container className={ classes.pageTitleWrapper }>
            <h1 className={ classes.pageTitle }>{ pageName }</h1>
          </Container>
          { rsvpContent }
        </Layout>
      </div>
    )
  }
}

export default withStyles(styles)(RSVPForm);
