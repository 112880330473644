import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "gatsby"
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    marginTop: 32,
    '& a': {
      textDecoration: 'none',
    }
  },
}))

const RSVPError = ({ lang }) => {
  const classes = useStyles()

  return (
    <Container maxWidth='md' className={classes.root}>
      <h2>Oops, something went wrong</h2>
      <Link to="/rsvp">
        <Box m="48px" />
        <Button>
          PLEASE TRY AGAIN
        </Button>
      </Link>
    </Container>
  )
}

export default RSVPError