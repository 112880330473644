import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { translateText } from '../config/translation'

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  cardTitle: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  cardSubtitle: {
    fontSize: 14,
    textAlign: 'center',
  },
  isAttending: {
    marginTop: 24,
    textAlign: 'left',
  }
}))

const RSVPGuestForm = ({ data, showFormErrors, handleChange, lang }) => {
  const classes = useStyles()

  let attendingWeddingText = null
  switch (data.isAttending) {
    case 'yes':
      attendingWeddingText = <span>{ translateText('is attending', lang) }</span>
      break
    case 'no':
      attendingWeddingText = (lang === 'pt') ? <span>estará <strong>ausente</strong></span> : <span>is <strong>not</strong> attending</span>
      break
    default: 
      attendingWeddingText = <span>&nbsp;</span>
      break
  }

  let nameIsValid = true
  let attendanceIsValid = true
  if (showFormErrors) {
    nameIsValid = (data.name.trim() !== '')
    attendanceIsValid = ['yes', 'no'].includes(data.isAttending)
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <h3 className={classes.cardTitle}>{data.name || 'Name'}</h3>
        <Box className={classes.cardSubtitle}>{attendingWeddingText}</Box>
        <Box>
          <TextField
            label={ translateText('Name', lang) }
            value={data.name}
            onChange={handleChange(data.id, 'name')}
            margin="normal"
            fullWidth
            error = {!nameIsValid}
          />
        </Box>
        <Box>
          <FormControl className={classes.isAttending} fullWidth  error={!attendanceIsValid}>
            <InputLabel shrink htmlFor="is-attending">
              { translateText('Are you attending?', lang) }
            </InputLabel>
            <Select
              value={data.isAttending}
              onChange={handleChange(data.id, 'isAttending')}
              input={<Input name="is-attending" id="is-attending" />}
              displayEmpty
            >
              <MenuItem value='yes'>{ translateText('Yes', lang) }</MenuItem>
              <MenuItem value='no'>{ translateText('No', lang) }</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <TextField
            label={ translateText("Email (optional)", lang) }
            value={data.email}
            onChange={handleChange(data.id, 'email')}
            margin="normal"
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            label={ translateText('Dietary preferences/restrictions', lang) }
            value={data.notes}
            onChange={handleChange(data.id, 'notes')}
            margin="normal"
            fullWidth
            multiline
            rows={5}
            variant='outlined'
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default RSVPGuestForm